<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12">
        <!-- {{ $route.query }} -->
        <c-table
          :url="'PurchaseWebService/get_merchant_list'"
          :per-page="10"
          :fields="columns"
          :columns="[
            'code',
            'name',
            'register_number',
            'start_date',
            'end_date',
            'create_date',
          ]"
          :filename="'Merchant List'"
          orderDirection="ASC"
          orderBy="code"
          hover
          bordered
          fixed
          caption=""
          :edit_has="true"
          :edit_route="'admin.purchase.merchant.edit'"
          :edit_params="{ id: 0 }"
        >
        </c-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Customers.list",
  data: function() {
    return {
      columns: [
        { label: "№", key: "index", class: "text-center" },
        {
          label: "Төрөл",
          key: "typeName",
          sortable: true,
          sort_key: "type_name",
        },
        { label: "Код", key: "code", sortable: true, sort_key: "code" },
        { label: "Нэр", key: "name", sortable: true, sort_key: "name" },
        { label: "Идэвхтэй эсэх", key: "isActive", class: "text-center" },
        { label: "Хэрэглэгчийн төлөх хүү", key: "interestList" },
      ],
    };
  },
  created: function() {
    if (this.checkPermission("admin.purchase.merchant.feeShow")) {
      this.$data.columns.push({
        label: "Байгууллагын шимтгэл",
        key: "fee",
      });

      this.$data.columns.push({
        label: "Тооцоо нийлэх өдөр",
        key: "calcDate",
      });

      this.$data.columns.push({
        label: "Гэрээ дуусах өдөр",
        key: "endDate",
      });
    }

    var _buttons = [];

    if (this.checkPermission("admin.purchase.merchant.config")) {
      _buttons.push({
        icon: "fa fa-cog ",
        label: "",
        variant: "outline-primary",
        route: "admin.purchase.merchant.config",
        route_param: "id",
      });
    }

    if (_buttons.length > 0) {
      this.$data.columns.push({
        label: "Үйлдэл",
        key: "actions",
        buttons: _buttons,
        class: "text-center",
      });
    }
  },
};
</script>
